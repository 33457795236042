import React, { useState, useEffect } from 'react';
import "./Checkout.css";
import CheckoutProduct from './CheckoutProduct';
import { useStateValue } from './StateProvider';
import Subtotal from './Subtotal';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { db } from './firebase';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function Checkout() {
  const [{ basket, user }, dispatch] = useStateValue();
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = db.collection("bellarmine")
      .onSnapshot((snapshot) => {
        const existingProductIds = new Set(snapshot.docs.map(doc => doc.id));
        
        const updatedBasket = basket.filter(item => existingProductIds.has(item.id));
        
        if (updatedBasket.length !== basket.length) {
          dispatch({
            type: "REMOVE_DELETED_PRODUCTS",
            updatedBasket: updatedBasket,
          });
        }
        
        setLoading(false);
      }, (error) => {
        console.error("Error listening to product changes:", error);
        setLoading(false);
      });

    return () => unsubscribe();
  }, [basket, dispatch]);

  const handleCheckout = async () => {
    setProcessing(true);
    console.log('Starting checkout process');

    try {
      console.log('Preparing request payload');
      const payload = {
        items: basket.map(item => ({
          id: item.id,
          title: item.title,
          price: item.price,
          sellerEmail: item.sellerEmail,
          image: item.image,
          description: item.description
        })),
        buyerEmail: user?.email,
      };
      console.log('Request payload:', payload);

      console.log('Sending request to create checkout session');
      let response;
      try {
        console.log('Axios POST request initiated');
        response = await axios.post(
          `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/api/create-checkout-session`,
          payload,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        console.log('Axios POST request completed');
        console.log('Received response:', response);
      } catch (axiosError) {
        console.error('Axios error occurred:', axiosError);
        if (axiosError.response) {
          console.error('Response data:', axiosError.response.data);
          console.error('Response status:', axiosError.response.status);
          console.error('Response headers:', axiosError.response.headers);
        } else if (axiosError.request) {
          console.error('No response received. Request details:', axiosError.request);
        } else {
          console.error('Error details:', axiosError.message);
        }
        throw axiosError;
      }

      console.log('Extracting session ID and URL from response');
      const { id, url } = response.data;
      console.log('Session ID:', id);
      console.log('Checkout URL:', url);

      if (url) {
        console.log('Redirecting to Stripe Checkout URL');
        window.location.href = url;
      } else {
        console.log('No URL provided, using redirectToCheckout');
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({ sessionId: id });
        if (error) {
          console.error('Stripe Checkout error:', error);
        }
      }
    } catch (error) {
      console.error('Error in checkout process:', error);
      console.error('Error name:', error.name);
      console.error('Error message:', error.message);
      console.error('Error stack:', error.stack);
    } finally {
      setProcessing(false);
      console.log('Checkout process completed');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className='checkout'>
      <div className='checkout__left'>
        <div>
          <h2 className='checkout__title'>
            Your Cart
          </h2>
          {basket.map(item => (
            <CheckoutProduct
              key={item.id}
              id={item.id}
              title={item.title}
              image={item.image}
              price={item.price}
              rating={item.rating}
              seller={item.seller}
              sellerEmail={item.sellerEmail}
            />
          ))}
        </div>
      </div>

      <div className='checkout__right'>
        <Subtotal handleCheckout={handleCheckout} processing={processing} />
      </div>
    </div>
  );
}

export default Checkout;