import React from 'react';
import './Privacy.css';

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      
      <section>
        <h2>1. Introduction</h2>
        <p>Welcome to StuShop. We are committed to protecting your personal information and your right to privacy. This Privacy Policy explains how we collect, use, and safeguard your information when you use our service.</p>
      </section>

      <section>
        <h2>2. Email Verification</h2>
        <p>To ensure the security and integrity of our platform, all users are required to verify their email addresses. This process helps us maintain a trusted community and prevent unauthorized access to accounts.</p>
      </section>

      <section>
        <h2>3. Data Storage</h2>
        <p>We prioritize the security of your data. All user information is stored in a secure Firebase project hosted by Google. We do not maintain a physical database of user information. This approach provides robust security measures and data protection protocols.</p>
      </section>

      <section>
        <h2>4. Use of Information</h2>
        <p>We use the information we collect solely for the purpose of providing and improving our service. This includes:</p>
        <ul>
          <li>Facilitating transactions between users</li>
          <li>Improving user experience</li>
          <li>Communicating with you about your account and our service</li>
          <li>Ensuring the security of our platform</li>
        </ul>
      </section>

      <section>
        <h2>5. Data Protection</h2>
        <p>We are committed to protecting your data. We will never sell your personal information to third parties. We only share your information as necessary to provide our service and comply with legal obligations.</p>
      </section>

      <section>
        <h2>6. Your Rights</h2>
        <p>You have the right to access, update, or delete your personal information at any time. If you wish to exercise these rights, please contact us at stushopteam@gmail.com.</p>
      </section>

      <section>
        <h2>7. Changes to This Policy</h2>
        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date at the top of this policy.</p>
      </section>

      <section>
        <h2>8. Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact us at stushopteam@gmail.com.</p>
      </section>

      <footer>
        <p>Last Updated: September 24, 2024</p>
      </footer>
    </div>
  );
}

export default PrivacyPolicy;