import React from 'react';
import './HowItWorks.css';

function HowItWorks() {
  return (
    <div className="how-it-works">
      <h1>How StuShop Works</h1>
      
      <section className="card">
        <h2>About StuShop</h2>
        <p>StuShop is your go-to college marketplace, connecting you with fellow students at your school for buying and selling. Our platform is designed exclusively for students, ensuring a trusted and familiar community for all your campus trading needs.</p>
      </section>

      <section className="card">
        <h2>Our Services</h2>
        <p>StuShop offers a secure platform for college students to buy and sell a wide range of items, including:</p>
        <ul>
          <li>Textbooks and course materials</li>
          <li>Dorm room essentials</li>
          <li>Electronics</li>
          <li>School supplies</li>
          <li>Clothing and accessories</li>
          <li>Sports equipment</li>
        </ul>
        <p>Our services include:</p>
        <ul>
          <li>School-specific marketplaces</li>
          <li>In-app messaging</li>
          <li>Seamless transaction process</li>
          <li>User verification through school email addresses</li>
        </ul>
      </section>

      <section className="card">
        <h2>Customer Service</h2>
        <p>Have questions or need assistance? We're here to help!</p>
        <p>Email: stushopteam@gmail.com</p>
        <p>For complaints or suggestions, please use our <a href="/contact">Contact page</a>.</p>
      </section>

      <section className="card" id="our-policies">
        <h2>Our Policies</h2>
        <h3 id="refund-policy">Return and Refund Policy</h3>
        <p>Once an order is marked as closed by the buyer, the transaction is final. If you're unsatisfied with an item, do not close the order. Instead, contact us within 24 hours of receiving the item via our Contact page or at stushopteam@gmail.com. We will review your request and may offer a full refund or facilitate a return with the seller. Refunds will be processed to the original payment method within 5-10 business days.</p>
        
        <h3 id="cancellation-policy">Cancellation Policy</h3>
        <p>To cancel an order before it's marked as closed, leave it open and contact us immediately via the Contact page or at stushopteam@gmail.com. We will process your cancellation request and issue a full refund within 3-5 business days. Once an order is marked as closed, our standard Return and Refund Policy applies.</p>
        
        <h3>Dispute Resolution</h3>
        <p>If you experience any issues with a transaction, please contact us immediately. We aim to resolve all disputes fairly and promptly. If we cannot resolve the issue directly, we may involve Stripe, our payment processor, in the dispute resolution process.</p>
        
        <h3>Legal Information</h3>
        <p>Sellers are responsible for reporting any income from sales on their taxes. StuShop is not responsible for individual sellers' tax obligations. We may provide transaction records to tax authorities as required by law. Users agree to comply with all applicable laws and regulations regarding online transactions and tax reporting.</p>
      </section>

      <section className="card">
        <h2>How to Use StuShop</h2>
        <ol>
          <li>Sign up with your school email address</li>
          <li>Browse items listed by students at your school</li>
          <li>Chat with sellers through our in-app messaging system</li>
          <li>Meet up on campus to complete the transaction</li>
          <li>Close the order if you're satisfied with the purchase</li>
        </ol>
      </section>

      <section className="card">
        <h2>Legal and Export Restrictions</h2>
        <p>StuShop prohibits the sale of illegal goods, weapons, explicit materials, and other items as detailed in our Terms of Service. We reserve the right to remove any listings that violate these policies and to suspend or terminate accounts engaged in prohibited activities.</p>
        <p>The following items are strictly prohibited on StuShop:</p>
        <ul>
          <li>Illegal goods or substances</li>
          <li>Weapons or explosives</li>
          <li>Alcohol or tobacco products</li>
          <li>Prescription drugs or controlled substances</li>
          <li>Counterfeit or stolen items</li>
          <li>Items that infringe on intellectual property rights</li>
          <li>Explicit materials</li>
          <li>Live animals</li>
          <li>Any items prohibited by your school's policies</li>
        </ul>
      </section>

      <section className="card">
        <h2>Terms and Conditions</h2>
        <p>By using StuShop, you agree to:</p>
        <ul>
          <li>Use a valid school email address</li>
          <li>Not create multiple accounts or share your account</li>
          <li>Not engage in fraudulent activities</li>
          <li>Communicate respectfully with other users</li>
          <li>Meet in safe, public locations for transactions</li>
          <li>Report any suspicious activities or policy violations</li>
          <li>Comply with all applicable laws and regulations</li>
        </ul>
      </section>
    </div>
  );
}

export default HowItWorks;