import React, { useEffect, useState } from 'react';
import './Orders.css';
import { db } from "./firebase";
import { useStateValue } from './StateProvider';
import Order from "./Order";

function Orders() {
  const [{ basket, user }, dispatch] = useStateValue();
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    if(user) {
      db.collection('users')
        .doc(user?.email)
        .collection('orders')
        .orderBy('created', 'desc')
        .onSnapshot(snapshot => {
          console.log('Fetched orders:', snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })));
          setOrders(snapshot.docs.map(doc => ({
            id: doc.id,
            data: doc.data()
          })))
        })
    } else {
      setOrders([])
    }
  }, [user])

  return (
    <div className='orders'>
        <h1>Your Open Orders</h1>
        <h1>Start a new <a href="./chats">chat</a> with the seller of your products to arrange a drop-off location</h1>
        <h2>Please click checkbox to close out all orders upon receiving products</h2>

        <div className='orders__order'>
          {orders?.map(order => (
            <Order order={order} collectionName="orders"/>
          ))}
        </div>
    </div>
  )
}

export default Orders
