import React, { useState, useEffect } from "react";
import "./Header.css";
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useStateValue } from "./StateProvider";
import { auth } from "./firebase";
import img1 from "./images/block_nerd.png";

function Header() {
  const [{ basket, user }, dispatch] = useStateValue();
  const navigate = useNavigate();

  const [userEmail, setUserEmail] = useState('');
  const [redirectLink, setRedirectLink] = useState('/');

  useEffect(() => {
    const email = user.email;
    setUserEmail(email);

    const domain = email.split('@')[1];
    if (domain === 'bellarmine.edu') {
      setRedirectLink('/bellarmine');
    } else if (domain === 'louisville.edu') {
      setRedirectLink('/louisville');
    } else {
      setRedirectLink('/');
    }
  }, []);

  const handleAuthentication = () => {
    if (user) {
      auth.signOut();
    }
  }

  return (
    <div className="header">
      <Link to={redirectLink}>
        <img
          className="header__logo"
          src={img1}
        />
      </Link>

      <div className="header__nav">
        <Link to={!user && '/'}>
          <div onClick={handleAuthentication}
           className="header__option">
            <span className="header__optionLineOne">Hello {!user ? "Guest" : user.email}</span>
            <span className="header__optionLineTwo">{user ? 'Sign Out' : navigate("/", { replace: true })}</span>
          </div>
        </Link>

        <Link to="/checkout">
          <div className="header__optionBasket">
            <ShoppingCartIcon />
            <span className="header__optionLineTwo header__basketCount">
              {basket?.length}
            </span>
          </div>
        </Link>

        <Link to="/productsubmit">
          <div className="contact__us">
              <p>Submit A Product</p>
          </div>
        </Link>

        <Link to='/orders'>
          <div className="header__orders">
              <p>Open Orders</p>
          </div>
        </Link>

        <Link to="/closedorders">
          <div className="header__closed__orders">
            <p>Closed Orders</p>
          </div>
        </Link>

        <Link to="/soldorders">
          <div className="header__sold__orders">
            <p>Sold Orders</p>
          </div>
        </Link>

        <Link to='/chats'>
          <div className="header__chats">
            <p>Chats</p>
          </div>
        </Link>

        <Link to="/contact">
          <div className="header__contact">
            <p>Contact</p>
          </div>
        </Link>

        <Link to="/howitworks">
          <div className="header__howitworks">
            <p>How It Works</p>
          </div>
        </Link>

        <Link to="/privacy">
          <div className="header__howitworks">
            <p>Privacy Policy</p>
          </div>
        </Link>

        <Link to="/tos">
          <div className="header__howitworks">
            <p>Terms Of Service</p>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default Header