import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChatEngine } from 'react-chat-engine';
import { auth } from './firebase';
import { useStateValue } from "./StateProvider";
import axios from 'axios';
import "./Chats.css"

const Chats = () => {
    const navigate = useNavigate();
    const [{ user }, dispatch] = useStateValue();
    const [loading, setLoading] = useState(true);
    const [chatConfig, setChatConfig] = useState(null);
    const [authError, setAuthError] = useState(null);

    const handleLogout = async () => {
        await auth.signOut();
        navigate('/');    
    }

    const getChatConfig = (email) => {
        if (email.includes('@bellarmine.edu')) {
            return {
                projectID: process.env.REACT_APP_CHAT_ENGINE_PROJECT_ID_BELLARMINE,
                privateKey: process.env.REACT_APP_CHAT_ENGINE_PRIVATE_KEY_BELLARMINE
            };
        } else if (email.includes('@louisville.edu')) {
            return {
                projectID: process.env.REACT_APP_CHAT_ENGINE_PROJECT_ID_LOUISVILLE,
                privateKey: process.env.REACT_APP_CHAT_ENGINE_PRIVATE_KEY_LOUISVILLE
            };
        }
        return null;
    }

    const sendEmailNotification = async (recipient, sender, message) => {
        console.log('Attempting to send email notification:');
        console.log('Recipient:', recipient);
        console.log('Sender:', sender);
        console.log('Message:', message);
        try {
            const response = await axios.post(`${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/chatNotify`, { recipient, sender, message });
            console.log('Notification API response:', response.data);
        } catch (error) {
            console.error('Failed to send notification:', error);
            if (error.response) {
                console.error('Error response:', error.response.data);
                console.error('Error status:', error.response.status);
            } else if (error.request) {
                console.error('No response received:', error.request);
            } else {
                console.error('Error setting up request:', error.message);
            }
        }
    };

    const handleNewMessage = async (chatId, message) => {
        console.log('handleNewMessage called with:');
        console.log('ChatId:', chatId);
        console.log('Full message object:', JSON.stringify(message, null, 2));
    
        if (message && message.sender && message.sender.username && message.text) {
            console.log('Message structure is as expected');
            
            try {
                // Fetch chat details to get all users in the chat
                const chatResponse = await axios.get(`https://api.chatengine.io/chats/${chatId}/`, {
                    headers: {
                        "project-id": chatConfig.projectID,
                        "user-name": user.email,
                        "user-secret": user.uid,
                    }
                });

                const chatDetails = chatResponse.data;
                const sender = message.sender.username;

                // Send notification to all users in the chat except the sender
                chatDetails.people.forEach(person => {
                    const recipient = person.person.username;
                    if (recipient !== sender) {
                        sendEmailNotification(recipient, sender, message.text);
                    }
                });

            } catch (error) {
                console.error('Error fetching chat details:', error);
            }
        } else {
            console.error('Unexpected message structure:', message);
        }
    };

    const handleNewChat = (chat) => {
        console.log('New chat created:', chat);
        console.log('This might trigger an email notification for added users');
    };

    const handleUserAdded = (chat, username) => {
        console.log(`User ${username} added to chat:`, chat);
        console.log('This should trigger an email notification for the added user');
    };

    useEffect(() => {
        if(!user){
            navigate("/");
            return;
        }

        const config = getChatConfig(user.email);
        if (!config) {
            console.error("No chat configuration found for this email domain");
            setLoading(false);
            return;
        }

        setChatConfig(config);

        axios.get("https://api.chatengine.io/users/me", {
            headers: {
                "project-id": config.projectID,
                "user-name": user.email,
                "user-secret": user.uid,
            }
        })
        .then((response) => {
            console.log("User exists in ChatEngine:", response.data);
            setLoading(false);
        })
        .catch((error) => {
            if (error.response && (error.response.status === 404 || error.response.status === 403)) {
                let formdata = new FormData();
                formdata.append('email', user.email);
                formdata.append('username', user.email);
                formdata.append('secret', user.uid);
                formdata.append('custom_json', JSON.stringify({ email_notifications: true }));

                return axios.post('https://api.chatengine.io/users', 
                    formdata,
                    { headers: { "private-key": config.privateKey } }
                );
            } else {
                throw error;
            }
        })
        .then((response) => {
            if (response) {
                console.log("New user created in ChatEngine:", response.data);
                setLoading(false);
            }
        })
        .catch((error) => {
            console.error("Error in ChatEngine setup:", error);
            setAuthError("Failed to authenticate with chat service. Please try logging out and in again.");
            setLoading(false);
        });
    }, [user, navigate]);

    if (authError) {
        return <div className="error-message">{authError}</div>;
    }

    if(!user || loading || !chatConfig) return "Loading...";

    return (
        <div className='chats-page'>
            <div className='nav-bar'>
                <div className='logo-tab'>
                </div>
                <div onClick={handleLogout} className='logout-tab'>
                </div>
            </div>

            <ChatEngine 
                height="calc(100vh - 66px)"
                projectID={chatConfig.projectID}
                userName={user.email}
                userSecret={user.uid}
                onNewMessage={handleNewMessage}
                onNewChat={handleNewChat}
                onUserAdded={handleUserAdded}
            />
        </div>
    );
}

export default Chats;