import React from 'react';
import "./TermsOfService.css"

function TermsOfService() {
  return (
    <div className="terms-of-service">
      <h1>Terms of Service</h1>
      
      <section>
        <h2>1. Introduction</h2>
        <p>Welcome to StuShop! By accessing or using our service, you agree to be bound by these Terms of Service.</p>
      </section>

      <section>
        <h2>2. Eligibility</h2>
        <p>To use StuShop, you must be at least 18 years old and either a current student at a recognized university or a recent graduate (within the last 12 months) of a recognized university. By using our service, you represent and warrant that you meet these eligibility requirements.</p>
      </section>

      <section>
        <h2>3. User Responsibilities</h2>
        <p>Users are responsible for the content they post on the site and how they interact with other users. You agree not to post any content that is illegal, harmful, or violates the rights of others.</p>
      </section>

      <section>
        <h2>4. Account Security</h2>
        <p>You are responsible for maintaining the confidentiality of your account information and for all activities under your account. You agree to notify us immediately of any unauthorized use of your account.</p>
      </section>

      <section>
        <h2>5. Prohibited Activities</h2>
        <p>Users may not engage in any of the following prohibited activities:</p>
        <ul>
          <li>Posting false or misleading information</li>
          <li>Harassing or threatening other users</li>
          <li>Engaging in fraudulent activities</li>
          <li>Using automated means to access the service</li>
          <li>Violating any applicable laws or regulations</li>
        </ul>
      </section>

      <section>
        <h2>6. Transactions</h2>
        <p>All transactions conducted through StuShop are subject to our Return and Refund Policy and Cancellation Policy as outlined on our website.</p>
      </section>

      <section>
        <h2>7. Data Use and Privacy</h2>
        <p>Your use of our service is subject to our Privacy Policy, which explains how we collect, use, and protect your information.</p>
      </section>

      <section>
        <h2>8. Intellectual Property Rights</h2>
        <p>All content on StuShop is owned by us or our licensors and is protected by intellectual property laws. Users retain ownership of their content but grant us a license to use it as necessary to operate the service.</p>
      </section>

      <section>
        <h2>9. Dispute Resolution</h2>
        <p>If you have any disputes with us or other users, please contact us immediately. We aim to resolve all disputes fairly and promptly.</p>
      </section>

      <section>
        <h2>10. Changes to Terms</h2>
        <p>We reserve the right to modify these Terms of Service at any time. We will notify users of significant changes via email or through our website.</p>
      </section>

      <section>
        <h2>11. Contact Information</h2>
        <p>If you have any questions about these Terms of Service, please contact us at stushopteam@gmail.com.</p>
      </section>

      <footer>
        <p>Last Updated: September 28, 2024</p>
      </footer>
    </div>
  );
}

export default TermsOfService;