import React, { useEffect, useState } from "react";
import "./Home.css";
import Product from "./Product";
import { db } from "./firebase";
import { useStateValue } from "./StateProvider";
import axios from 'axios';

function Home() {
  const [{ user }] = useStateValue();
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25;

  useEffect(() => {
    const unsubscribe = db.collection("bellarmine").onSnapshot((snapshot) => {
      setProducts(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    const filtered = products.filter((product) =>
      product.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredProducts(filtered);
    setCurrentPage(1);
  }, [products, searchQuery]);

  useEffect(() => {
    if (user && user.email.includes('@bellarmine.edu')) {
      addUserToChatEngine(user);
    }
  }, [user]);

  const addUserToChatEngine = async (user) => {
    const config = {
      projectID: process.env.REACT_APP_CHAT_ENGINE_PROJECT_ID_BELLARMINE,
      privateKey: process.env.REACT_APP_CHAT_ENGINE_PRIVATE_KEY_BELLARMINE
    };

    try {
      await axios.get("https://api.chatengine.io/users/me", {
        headers: {
          "project-id": config.projectID,
          "user-name": user.email,
          "user-secret": user.uid,
        }
      });
      console.log("User already exists in ChatEngine");
    } catch (error) {
      if (error.response && (error.response.status === 404 || error.response.status === 403)) {
        let formdata = new FormData();
        formdata.append('email', user.email);
        formdata.append('username', user.email);
        formdata.append('secret', user.uid);
        formdata.append('custom_json', JSON.stringify({ email_notifications: true }));

        try {
          const response = await axios.post('https://api.chatengine.io/users', 
            formdata,
            { headers: { "private-key": config.privateKey } }
          );
          console.log("New user created in ChatEngine:", response.data);
        } catch (postError) {
          console.error("Error creating new user in ChatEngine:", postError);
        }
      } else {
        console.error("Error checking user in ChatEngine:", error);
      }
    }
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleDeleteProduct = (productId) => {
    db.collection("bellarmine").doc(productId).delete()
      .then(() => {
        console.log("Product successfully deleted!");
      })
      .catch((error) => {
        console.error("Error removing product: ", error);
      });
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredProducts.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="home">
      <div className="home__business-info">
        <h1>Welcome to StuShop</h1>
        <p>Your trusted college marketplace for buying and selling within your campus community.</p>
        <p>We connect students to exchange textbooks, dorm essentials, electronics, and more.</p>
        <p className="chat-prompt">
        Have a question about a product currently for sale? 
       <a href="/chats" className="chat-link"> Send a chat</a> to the seller!
        </p>
      </div>

      <div className="home__search">
        <input
          type="text"
          placeholder="Search products..."
          value={searchQuery}
          onChange={handleSearch}
        />
      </div>

      <div className="home__container">
        {currentItems.length > 0 && (
          <div className="home__row">
            {currentItems.map((product) => (
              <Product
                key={product.id}
                id={product.id}
                title={product.title}
                price={product.price}
                formattedPrice={product.formattedPrice}
                rating={product.rating}
                image={product.image}
                description={product.description}
                seller={product.seller}
                sellerEmail={product.sellerEmail}
                userEmail={user?.email}
                onDelete={handleDeleteProduct}
              />
            ))}
          </div>
        )}
      </div>

      <div className="pagination">
        <button 
          onClick={() => paginate(currentPage - 1)} 
          disabled={currentPage === 1}
        >
          &larr;
        </button>
        <span>
          {indexOfFirstItem + 1}-{Math.min(indexOfLastItem, filteredProducts.length)} of {filteredProducts.length}
        </span>
        <button 
          onClick={() => paginate(currentPage + 1)} 
          disabled={currentPage === totalPages}
        >
          &rarr;
        </button>
      </div>

      <footer className="home__footer">
        <div className="footer__info">
          <h3>StuShop</h3>
          <p>A secure platform for college students to buy and sell within their campus community.</p>
          <p>Contact: stushopteam@gmail.com</p>
        </div>
        <nav className="footer__links">
          <a href="/howitworks">About Us</a> {' | '}
          <a href="/contact">Contact</a> {' | '}
          <a href="/privacy">Privacy Policy</a> {' | '}
          <a href="/tos">Terms of Service</a> {' | '}
          <a href="/howitworks#refund-policy">Refund Policy</a> {' | '}
          <a href="/howitworks#cancellation-policy">Cancellation Policy</a>
        </nav>
        <p className="footer__copyright">&copy; 2024 StuShop. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default Home;